























import { Component, Vue } from 'vue-property-decorator';

@Component
export default class FamilyGallery extends Vue {
    pictures = [
        'https://pbs.twimg.com/media/EqQneSjXYAIFr3s?format=jpg',
        'https://i.imgur.com/z7Whtdc.png',
        'https://imgur.com/mTODIOi.png',
        'https://imgur.com/cBDk7wD.png',
        'https://imgur.com/WWs4LYy.png',
        'https://imgur.com/MxPcSae.png',
        'https://imgur.com/5lYBer6.png',
        'https://imgur.com/0xBomnQ.png',
    ]
}
