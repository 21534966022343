



















































import { Component, Vue } from 'vue-property-decorator';

@Component
export default class FamilyMembers extends Vue {
  family = [
    {name: 'Alton Carter', body: 'Worked from poverty to success. Alton is a proud husband and father of two sons. Together, with Alton, the Carter family strives to push forward.', src: require(`@/assets/team-1.png`)},
    {name: 'Kristin Carter', body: 'After meeting Alton at OSU, Kristin immediately fell in love with him. Since then, their relationship remained strong, and she became a happy mother of two.', src: require(`@/assets/team-2.png`)},
    {name: 'Kelton Carter', body: 'Born and raised in Stillwater, Oklahoma, Kelton Carter is the oldest of the two brothers. He loves caring people and also held Colin after he was born.', src: require(`@/assets/team-3.png`)},
    {name: 'Colin Carter', body: 'Colin was born after Kelton at the Stillwater Medical Hospital. He grew up to love traveling and volunteering for his community while also creating paintings.', src: require(`@/assets/team-4.png`)},
  ]
}
