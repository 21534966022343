












import { Component, Vue } from 'vue-property-decorator';

import HeroAlt from '@/components/sections/HeroAlt.vue';
import FamilyMembers from '@/components/sections/FamilyMembers.vue';
import FamilyGallery from '@/components/sections/FamilyGallery.vue';
import InfoAlt from '@/components/sections/InfoAlt.vue';

@Component({
    components: {
        HeroAlt,
        FamilyMembers,
        FamilyGallery,
        InfoAlt,
    },
    metaInfo: () => ({
        title: 'The Carter Family'
    })
})
export default class Family extends Vue {
    
}
